<template>
  <div class="row">
    <div class="col-sm-8" style="text-align: left;">
      <p><span>Merging districts combines schools and users for the two districts</span></p>
    </div>
    <div class="col-sm-3">
      <div class="row">
        <div style="text-align: left;">
          <label>Search</label>
          <input v-model="searchText" type="text" spellcheck="false" placeholder="Name, Email, Organization"/>
        </div>
        <div style="text-align: right;">
          <button type="button" class="button btn-primary bi-search" @click="getDistrictsSearch">Search</button>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="row">
      <h3 style="font-weight: bold;">Merging</h3>
      <div class="col-sm " style="text-align: left;">
        <h6>Name: {{ distToRemove.districtName }}</h6>
        <h6>CDN: {{ distToRemove.districtCdn }}</h6>
        <h6>ESU: {{ distToRemove.districtEsu }}</h6>
      </div>
      <div class="col-sm " style="text-align: left;">
        <h6>Address: {{ distToRemove.primaryDistrictAddress }}</h6>
        <h6>Phone: {{ distToRemove.districtPhoneNumber }}</h6>
      </div>
    </div>
  </div>
  <div class="container-lg mt-2" v-if="showResults">
    <table id="districtTable" class="table table-bordered table-striped">
      <thead>
      <tr>
        <th v-for="(value, name, index) in fieldHeaders" :key="index" @click="sortTable(name)">{{ value }}
        <i :class="name === prevSearchCol && storedReverse ? 'bi bi-sort-up' : 'bi bi-sort-down'"
           aria-label='Sort Icon'></i>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in updatedList" :key="item">
        <td v-for="(value, name, index) in fieldHeaders" :key="index">
          <router-link v-if="name === 'districtName'" :to="{name: 'MergeDistricts', params: {idRemovingDistrict: idRemovingDistrict, idKeepingDistrict: item['idDistrict']}}">{{item[name]}}</router-link>
          <p v-else>{{ item[name]}}</p>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {computed, onBeforeMount, ref} from "vue";
import axios from "axios";
import {displayDistrictById, searchDistricts} from "@/Helpers/ApiCalls/DistrictAPICalls";
import {sortBy} from "lodash/collection";
import {createPhoneInputMask} from "@/InputMaskFunctions";

export default {
  name: "DistrictMergeSearch",
  props: {
    idRemovingDistrict: Number
  },
  setup(props) {
    const searchText = ref("");
    const isLoaded = ref(false);
    const districts = ref([]);
    const distToRemove = ref({});
    const sort = ref(false);
    const updatedList = ref(false);
    const prevSearchCol = ref("");
    const storedReverse = ref(false);

    const fieldHeaders = ref({
      districtName: "Name",
      districtCdn: "CDN",
      primaryDistrictAddress: "Address",
      districtPhoneNumber: "Phone",
      districtEsu: "ESU",
      districtCity: "City",
      districtZipCode: "Zip Code",
    })

    onBeforeMount(() => {
      getDistToRemove();
    })

    async function getDistToRemove() {
      isLoaded.value = false;
      await axios.get(displayDistrictById(props.idRemovingDistrict), {
        params: {
          showUserCount: false
        }
      }).then((result) => {
            if (result.status === 200) {
              distToRemove.value = result.data;
            }
          }).finally(() => {
            isLoaded.value = true;
          })
    }

    async function getDistrictsSearch() {
      await axios.get(searchDistricts(), {
        params: {
          searchText: searchText.value
        }
      }).then((result) => {
        if (result.status === 200) {
          districts.value = result.data
        }
      }).finally(() => {
        updatedList.value = districts.value.filter(district => district.idDistrict !== parseInt(props.idRemovingDistrict));
      })
    }

    const sortTable = (col) => {
      sort.value = true;
      if (col !== prevSearchCol.value) {
        storedReverse.value = false;
        updatedList.value = sortBy(districts.value, col);
      } else {
        storedReverse.value = !storedReverse.value;
        updatedList.value = updatedList.value.reverse();
      }
      prevSearchCol.value = col;
    }

    const showResults = computed(() => {
      return districts.value.length > 0;
    })

    return {
      isLoaded,
      districts,
      distToRemove,
      searchText,
      updatedList,
      showResults,
      fieldHeaders,
      prevSearchCol,
      storedReverse,
      sortTable,
      getDistrictsSearch
    }
  }
}
</script>

<style scoped>

</style>